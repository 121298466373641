import {Visibility} from "./visibility";
import {GamePlayTemplateData} from "./game-play-template-data";
import {UserGameOwnership} from "./user-game-ownership";
import {PlayStatsResponse, GamePlayStatsResponse, TimeWindowResponse} from "./statsResponses";
import {UserGamePlayAccess} from "./user";
import {GamePlayConfig} from "./game-play-config";

export type UserPersonalResponse = {
    id: string
    email: string
    username: string
    name: string,
    locale: string,
    registeredSince: Date,
    firstLoginDone?: boolean,
    firstLoginAt?: Date,
    description?: string,
    avatarUrl?: string,
    bggUsername?: string,
    hasPassword: boolean,
    visibility: Visibility,
    messaging: Visibility,
    playTagging: Visibility,
}

export type UserResponse = {
    id: string
    username: string
    name: string,
    description?: string,
    avatarUrl?: string,
    visibility: Visibility,
    messaging: Visibility,
    playTagging: Visibility,
    favState?: UserFavState,
    isBlocked?: boolean,
}

export type UsersBasicResponse = {
    list: UserBasicResponse[]
}

export type UserBasicResponse = {
    id: string
    username: string
    name: string,
    avatarUrl?: string,
}

export type AuthResponse = {
    token: TokenResponse,
    refreshToken: TokenResponse,
}

export type TokenResponse = {
    token: string
    issuedAt: Date,
    expiresAt: Date
}

export type UserGamesResponse = {
    userId: string
    username: string
    games: UserGameResponse[]
}

export type GameImage = {
    url: string
    previewUrl: string
    thumbnailUrl: string
}

export type UserGameResponse = {
    id: string
    slug: string
    name: string
    image: GameImage
    isExpansion: boolean
    expands: string[]
    yearPublished?: number
    minPlayerCount: number
    maxPlayerCount: number
    minGoodPlayerCount: number
    maxGoodPlayerCount: number
    bestPlayerCount: number
    minPlayTimeMinutes: number
    maxPlayTimeMinutes: number
    minAge: number
    complexity: number
    bggRating?: number
    ludoyaRating?: number
    annotations: UserGameAnnotations
    viewerAnnotations?: UserGameAnnotations
    stats?: UserGameStatsResponse
}

export type UserGameAnnotations = {
    versions?: BoardGameVersionResponse[]
    ownership?: UserGameOwnership
    ownershipSince?: Date
    expertise?: number
    rating?: number
    notes?: string
    price?: number,
    tags?: string[]
}

export type UserGameStatsResponse = {
    playCount: number
    winCount: number
    totalPlayTime: string
    totalPlaySeconds: number
    maxScore: number
    firstPlayDate?: Date
    lastPlayDate?: Date
}

export type UserBggDataResponse = {
    username: string
    config?: BggSyncConfig
    data?: BggSyncData
}

export type BggSyncConfig = {
    importType: BggPortType
    exportType: BggPortType
    autoSync: boolean
    password?: string
}

export type BggSyncData = {
    status: BggSyncStatus
    collectionImport?: BggPortCounts
    playsImport?: BggPortCounts
    collectionExport?: BggPortCounts
    playsExport?: BggPortCounts
    startedAt?: Date
    finishedAt?: Date
    processDuration?: string
    errorMessage?: string
    lastCompletedAt?: Date
}

export type BggPortCounts = {
    srcCount: number
    dstCount: number
    newCount: number
    updatedCount: number
}

export enum BggPortType {
    NONE = "NONE",
    COLLECTION = "COLLECTION",
    PLAYS = "PLAYS",
    BOTH = "BOTH"
}

export enum BggSyncStatus {
    PENDING = "PENDING",
    WAITING_FOR_BGG = "WAITING_FOR_BGG",
    IMPORTING = "IMPORTING",
    EXPORTING = "EXPORTING",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR"
}

export type UserNotificationSettings = {
    notifications?: UserNotificationSetting[]
    sendAnyEmails?: boolean
    sendMessageEmails?: boolean
    radiusKm?: number
}

export type UserNotificationSetting = {
    type: NotificationType
    setting: UserNotificationSettingType
}

export enum NotificationType {
    PLATFORM_NEWS = "PLATFORM_NEWS",
    ADDED_TO_PLAY = "ADDED_TO_PLAY",
    FOLLOWED = "FOLLOWED",
    MENTIONED_IN_POST = "MENTIONED_IN_POST",
    MENTIONED_IN_COMMENT = "MENTIONED_IN_COMMENT",
    LIKED_POST = "LIKED_POST",
    COMMENTED_POST = "COMMENTED_POST",
    LIKED_REVIEW = "LIKED_REVIEW",
    COMMENTED_REVIEW = "COMMENTED_REVIEW",
    NEW_MEETUP = "NEW_MEETUP",
    NEW_PLANNED_PLAY = "NEW_PLANNED_PLAY",
    MEETUP_ATTENDANT = "MEETUP_ATTENDANT",
    PLANNED_PLAY_PARTICIPANT = "PLANNED_PLAY_PARTICIPANT",
    MEETUP_REMINDER = "MEETUP_REMINDER",
    PLANNED_PLAY_REMINDER = "PLANNED_PLAY_REMINDER",
}

export enum UserNotificationSettingType {
    EMAIL = "EMAIL",
    IN_APP = "IN_APP",
    DISABLED = "DISABLED"
}

export type BoardGameNamesResponse = {
    list: BoardGameBasicResponse[]
}

export type BoardGameBasicResponse = {
    id: string
    slug: string
    name: string
    image: GameImage
    yearPublished?: number
}

export type BoardGameResponse = {
    id: string
    slug: string
    name: string
    description?: string
    image: GameImage
    minPlayerCount: number
    maxPlayerCount: number
    minGoodPlayerCount: number
    maxGoodPlayerCount: number
    bestPlayerCount: number
    minPlayTimeMinutes: number
    maxPlayTimeMinutes: number
    minAge: number
    complexity: number
    yearPublished?: number
    bggUrl: string
    userAnnotations?: UserGameAnnotations
    userFollowedAnnotations?: UserFavGameResponse[]
}

export type BoardGameListResponse = {
    list: BoardGameResponse[]
}

export type UserFavGameResponse = {
    id: string
    username: string
    name: string
    avatarUrl?: string
    ownership?: UserGameOwnership
    ownershipSince?: Date
    price?: number
}

export type BoardGameVersionResponse = {
    id: string
    name: string
    languages: string[]
    yearPublished?: number
    image: GameImage
}

export type BoardGameExpansionsResponse = {
    gameId: string
    expansions: BoardGameBasicResponse[]
    bases: BoardGameBasicResponse[]
}

export type BoardGameVersionsResponse = {
    gameId: string
    list: BoardGameVersionResponse[]
}

export type UserFavsResponse = {
    list: UserFavResponse[]
}

export type UserFavCountsResponse = {
    received: number
    sent: number
    reciprocal: number
}

export type UserFavResponse = {
    id: string
    username: string
    name: string
    avatarUrl?: string
    state: UserFavState
}

export enum UserFavState {
    SENT = "SENT",
    RECEIVED = "RECEIVED",
    RECIPROCAL = "RECIPROCAL",
    BLOCKED = "BLOCKED",
}

export type GamePlayBasicResponse = {
    id: string
    game: BoardGameBasicResponse
    players: GamePlayerBasicResponse[]
    date: Date
    count: number
    duration?: string

    // These are not sent by the backend, but added by the frontend
    anyWinner?: boolean
    topScore?: number
}

export type GamePlayerBasicResponse = {
    name: string
    userId?: string
    userPlayerId?: string
    username?: string
    avatarUrl?: string
    isWinner?: boolean
    score?: number
    color?: string
    team?: string
    isBot?: boolean
}

export type GamePlayResponse = {
    id: string
    game: BoardGameBasicResponse
    expansions: BoardGameBasicResponse[]
    players: GamePlayerResponse[]
    config: GamePlayConfig
    state: GamePlayState
    template?: GamePlayTemplateResponse
    startedAt?: Date
    finishedAt?: Date
    timerStartedAt?: Date
    date: Date
    count: number
    duration?: string
    location?: LocationResponse
    postIds: string[]
    invertedScoring?: boolean
    createdAt: Date
    updatedAt: Date
    access?: UserGamePlayAccess
    notes?: string
    includedInProfile?: boolean
    anonymous?: boolean

    // These are not sent by the backend, but added by the frontend
    anyWinner?: boolean
    topScore?: number
    durationSeconds?: number
}

export enum GamePlayState {
    PREPARING = "PREPARING",
    PLAYING = "PLAYING",
    SCORING = "SCORING",
    FINISHED = "FINISHED"
}

export type GamePlayerResponse = {
    name: string
    userId?: string
    userPlayerId?: string
    username?: string
    avatarUrl?: string
    isWinner?: boolean
    score?: number
    scoreBreakdown?: {
        [key: string]: number | undefined;
    }
    startPosition?: number
    color?: string
    role?: string,
    team?: string
    isBot?: boolean
    timerStartedAt?: Date
    totalPlayTime?: string
    // These are not sent by the backend, but added by the frontend
    updatingScore?: boolean
    editedScore?: any
    editedScoreBreakdown?: {
        [key: string]: any | undefined;
    }
    loadedScore?: number
    loadedScoreBreakdown?: {
        [key: string]: number | undefined;
    }
    totalPlayTimeSeconds?: number
    elapsedTime?: string
    totalElapsedTime?: string
    players?: GamePlayerResponse[] // For team players
}

export type GamePlayPeriodStatsResponse = {
    timeWindow: TimeWindowResponse
    playStats: PlayStatsResponse
    games: GamePlayStatsResponse[]
}

export type PlayUpdateData = {
    id: string
    users: GamePlayUserResponse[]
    players: GamePlayerResponse[]
    state: GamePlayState
    config: GamePlayConfig
    startedAt?: Date
    finishedAt?: Date
    timerStartedAt?: Date
    date: Date
    duration?: string
    setupDuration?: string
    playDuration?: string
    scoringDuration?: string
    postIds: string[]
    template?: GamePlayTemplateResponse
}

export type GamePlayUserResponse = {
    userId: string
    access: UserGamePlayAccess
    notes?: string
}

export type GamePlayTemplateResponse = {
    id: string
    name: string
    description?: string
    author: UserBasicResponse
    language: string
    data: GamePlayTemplateData
}

export type UserPlayerListResponse = {
    list: UserPlayerBasicResponse[]
}

export type UserPlayerBasicResponse = {
    id: string
    name: string
    avatarUrl?: string
    isBot: boolean
    anonymous: boolean
    bggUsername?: string
    playCount?: number
}

export type UserPlayerResponse = {
    id: string
    name: string
    avatarUrl?: string
    isBot: boolean
    anonymous: boolean
    creator: UserBasicResponse
    bggUsername?: string
    linkedUser?: UserBasicResponse
}

export type AvatarUploadResponse = {
    avatarUrl: string
}

export type UserCheckResponse = {
    exists: boolean,
    verified: boolean
}

export enum PostType {
    POST = "POST",
    GAME_PLAY = "GAME_PLAY",
    GAME_OWNERSHIP_CHANGED = "GAME_OWNERSHIP_CHANGE",
    GAME_REVIEW = "GAME_REVIEW",
}

export type NoPostData = {}

export type GameOwnershipChangePostData = {
    ownership: UserGameOwnership
}

export type GamePlayPostData = {
    id: string
    players: GamePlayerResponse[]
    date: Date
    duration?: string
}

export type PostResponse = {
    id: string
    game?: BoardGameResponse
    creatorUser: UserBasicResponse
    type: PostType
    data: NoPostData | GameOwnershipChangePostData | GamePlayPostData
    content?: string
    images: PostImageUrl[],
    likeCount: number
    commentCount: number
    liked: boolean
    createdAt: Date
    likes?: UserBasicResponse[]
    comments?: PostCommentResponse[]
}

export type PostImageUrl = {
    url: string,
    previewUrl: string
}

export type PostCommentResponse = {
    id: string
    parentId?: string
    creatorUser: UserBasicResponse
    content: string
    likeCount: number
    commentCount: number
    liked: boolean
    createdAt: Date
    likes?: UserBasicResponse[]
    comments?: PostCommentResponse[]
}

export type IdResponse = {
    id: string
}

export type NotificationResponse = {
    id: string
    triggerUser: UserBasicResponse
    data: NotificationData,
    read: boolean,
    createdAt: Date,

    // These are not sent by the backend, but added by the frontend
    markedAsRead?: boolean
}

export type NotificationsResponse = {
    list: NotificationResponse[]
}

export type NotificationData = {
    type: string
}

export type AddedToPlayNotificationData = {
    play: GamePlayBasicResponse
}

export type FollowedNotificationData = {
    follower: UserBasicResponse
    isFollowBack: boolean
}

export type MentionedInPostNotificationData = {
    postId: string
    postContent?: string
}

export type MentionedInCommentNotificationData = {
    postId: string
    commentId: string
    commentContent?: string
}

export type LikedPostNotificationData = {
    postId: string
    postContent?: string
}

export type CommentedPostNotificationData = {
    postId: string
    commentId: string
    commentContent?: string
}

export type LikedReviewNotificationData = {
    reviewId: string
    reviewedGameSlug: string
    reviewedGameName: string
    reviewContent?: string
}

export type CommentedReviewNotificationData = {
    reviewId: string
    reviewedGameSlug: string
    reviewedGameName: string
    commentId: string
    commentContent?: string
}

export type NewMeetupNotificationData = {
    meetupId: string
    meetupTitle: string
    meetupStartDate: Date
}

export type NewPlannedPlayNotificationData = {
    playId: string
    playTitle: string
    playStartDate: Date
}

export type MeetupAttendantNotificationData = {
    meetupId: string
    meetupTitle: string
    user: UserBasicResponse
}

export type PlannedPlayParticipantNotificationData = {
    playId: string
    playTitle: string
    user: UserBasicResponse
}

export type MeetupReminderNotificationData = {
    meetupId: string
    meetupTitle: string
    meetupStartTime: Date
    meetupStartsIn: string
}

export type PlannedPlayReminderNotificationData = {
    playId: string
    playTitle: string
    playStartTime: Date
    playStartsIn: string
}

export type ConversationsResponse = {
    list: ConversationSummaryResponse[]
}

export type ConversationSummaryResponse = {
    id: string
    otherUser: UserBasicResponse
    lastMessage: MessageResponse
    lastReadMessageIndex: number
    unreadMessageCount: number
}

export type ConversationResponse = {
    id: string
    otherUser: UserBasicResponse
    messages: MessageResponse[]
    lastReadMessageIndex: number
}

export type ConversationMessagesResponse = {
    list: MessageResponse[]
}

export type MessageResponse = {
    id: string
    conversationId: string
    index: number
    senderUserId: string
    content: string
    edited: boolean
    deleted: boolean
    createdAt: Date
    updatedAt: Date
}

export type UserMeetupsResponse = {
    created: MeetupResponse[],
    joined: MeetupResponse[],
    invited: MeetupResponse[],
}

export type MeetupsResponse = {
    meetups: MeetupResponse[]
    plannedPlays: PlannedPlayResponse[]
}

export type MeetupResponse = {
    id: string
    title: string
    description?: string
    organizer: UserBasicResponse
    location?: LocationResponse
    distance: number
    startsAt: string
    endsAt?: string
    timeZone: string
    imageUrl?: string
    visibility: Visibility
    ownAttendance?: UserMeetupAttendanceResponse
    attendeeCount?: number
    attendees?: MeetupAttendanceResponse[]
    sortWeight: number
}

export type UserMeetupAttendanceResponse = {
    confirmed: boolean
}

export type MeetupAttendanceResponse = {
    user: UserBasicResponse
    confirmed: boolean
}

export type LocationsResponse = {
    list: LocationResponse[]
}

export type PlannedPlaysResponse = {
    list: PlannedPlayResponse[]
}

export type PlannedPlayResponse = {
    id: string
    organizer: UserBasicResponse
    game?: BoardGameBasicResponse
    meetup?: MeetupResponse
    title?: string
    description?: string
    startsAt?: string
    timeZone?: string
    location?: LocationResponse
    distance: number
    imageUrl?: string
    slots: number
    visibility: Visibility
    playId?: string
    participants: PlannedPlayParticipantResponse[]
    sortWeight: number
}

export type PlannedPlayParticipantResponse = {
    user: UserBasicResponse
    addedBy?: UserBasicResponse
    userPlayers: UserPlayerBasicResponse[]
}

export type LocationResponse = {
    id: string
    name: string
    address?: string
    latitude?: number
    longitude?: number
    visibility: Visibility
    isDefault?: boolean
}

export type SearchResponse = {
    list: SearchResultResponse[]
}

export type SearchResultResponse = {
    user?: UserBasicResponse
    boardGame?: BoardGameBasicResponse
}

export type ShopResponse = {
    id: string
    name: string
    url: string
    logoUrl: string
    country: string
}

export type ShopItemsResponse = {
    items: ShopItemResponse[]
}

export type ShopItemResponse = {
    id: string
    shop: ShopResponse
    name: string
    price: number
    currencySymbol: string
    availability: Availability
    url: string
}

export type GameReviewsResponse = {
    ownReview?: ReviewResponse,
    totalReviews: number,
    averageRating?: number,
    averageFunRating?: number,
    averageInteractionRating?: number,
    averageAppealRating?: number,
    averageReplayabilityRating?: number,
    averageAccessibilityRating?: number,
    otherReviews: ReviewResponse[]
}

export type ReviewsResponse = {
    list: ReviewResponse[]
}

export type ReviewCountResponse = {
    count: number
}

export type ReviewResponse = {
    id: string,
    creatorUser?: UserBasicResponse,
    game?: BoardGameBasicResponse,
    rating: number,
    funRating?: number,
    interactionRating?: number,
    appealRating?: number,
    replayabilityRating?: number,
    accessibilityRating?: number,
    content?: string,
    likeCount: number
    commentCount: number
    liked: boolean
    createdAt: Date,
    updatedAt: Date
    likes?: UserBasicResponse[]
    comments?: PostCommentResponse[]
}

export type PostableResponse = {
    id: string
    creatorUser?: UserBasicResponse,
    game?: BoardGameBasicResponse,
    type?: PostType
    data?: NoPostData | GameOwnershipChangePostData | GamePlayPostData
    rating?: number
    funRating?: number,
    interactionRating?: number,
    appealRating?: number,
    replayabilityRating?: number,
    accessibilityRating?: number,
    content?: string
    likeCount: number
    commentCount: number
    liked: boolean
    images?: PostImageUrl[]
    createdAt: Date
    likes?: UserBasicResponse[]
    comments?: PostCommentResponse[]
}

export enum Availability {
    AVAILABLE = "IN_STOCK",
    OUT_OF_STOCK = "OUT_OF_STOCK",
    PREORDER = "PREORDER",
    UNKNOWN = "UNKNOWN"
}

export interface Page<T> {
    totalNumber: number;
    pageNumber: number;
    elements: T[];
}

export enum FeatureStatus {
    PLANNED = 'PLANNED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
}

export interface RoadmapResponse {
    categories: RoadmapCategoryResponse[];
}

export interface RoadmapCategoryResponse {
    name: string;
    features: RoadmapFeatureResponse[];
}

export interface RoadmapFeatureResponse {
    id: string;
    name: string;
    markdownDescription: string;
    status: FeatureStatus;
    votes: number;
    ownVote: number;
}

export interface NewsResponse {
    news: Page<NewsArticleResponse>;
}

export interface NewsArticleResponse {
    id: string;
    title: string;
    markdownContent: string;
    imageUrl: string;
    createdAt: string;
}
